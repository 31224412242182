.work-report {
    background-color: #ffffff;
    border: 0px solid;
    border-radius: 8px;
    height: 60vh;
    margin-top: 10px
}

.status {
    float: right;
    margin-right: 38px;
}

.workreport-heading {
    font-weight: 500;
    margin-top: 20px;
    font-size: 20px
}

.date-input {
    width: 132px !important;
    float: right;
}

.card {
    .cardText {
        line-height: 1.3rem;

        .card-title {
            font-size: 14px;
        }
    }

    .icons {
        width: 50px;
        color: #01579b;
        margin-right: 10px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

}

.chart-card {
    .card-title {
        font-size: 16px;
        border-bottom: 1px solid #e9edf4;
    }
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
    font-weight: 600 !important;
}
tbody, tbody tr, tr td, tr th {
    font-family: "Jost", sans-serif !important;
}
.task-list {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0px 0 0;
    color: #3c4858;

    &::before {
        content: "";
        position: absolute;
        top: 4px;
        bottom: 0;
        height: 90%;
        left: 15px;
        border-left: 1px solid #e0e9f1;
    }

    li {
        margin-bottom: 10px;
        padding-left: 55px;
        position: relative;

        h6 {
            font-size: 0.875rem;

            .date {
                font-size: 0.7em;
                display: inline-block;
                margin-left: 6px;
                font-weight: normal;
            }
        }

        .info-text {
            font-size: 0.8em;
        }

        .task-icon {
            position: absolute;
            left: 10px;
            top: 5px;
            border-radius: 50%;
            padding: 2px;
            width: 12px;
            height: 12px;
            z-index: 2;
        }
    }
}