.unread_notifications {
    .notification_space_unread {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
    }

    .notification_space {
        background: rgb(248, 247, 247);
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
    }

    // .body_notifications {
    //     height: 35px;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;
    //     overflow: hidden;
    // }

    h6 {
        font-weight: 600;
        color: #01579b;
    }

    p {
        font-size: small;
    }

    .notification_date {
        color: gray;
        font-weight: 600;
    }

    .view_status {
        height: 8px;
        width: 8px;
        background-color: #01579b;
        border-radius: 50%;
    }

    .lines {
        width: 35%;
        height: 2px;
        border-bottom: 1px solid gray;
    }

    .icon {
        height: 20px;
        width: 20px;
    }
}

.MuiBox-root.css-19kzrtu {
    padding: 10px;
}