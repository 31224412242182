.calendar-block {
    .fc .fc-day-other .fc-daygrid-day-top {
        z-index: 100;
    }
    .fc .fc-bg-event{
        opacity: 0.8 !important;
    }
    .fc-header-toolbar {
        margin-bottom: 0.8rem !important;

        .fc-toolbar-title {
            font-size: 1rem !important;
        }
    }

    .fc-button-primary {
        background: unset !important;
        border: unset !important;;
        color: gray !important;;

        &:hover {
            background: unset !important;;
            outline: unset !important;;
            color: #01579b !important;;
        }
    }

    .fc-col-header-cell-cushion {
        color: black;
        text-decoration: none;
        font-weight: 600;
    }

    .fc-daygrid-day-number {
        color: black;
        text-decoration: none;
    }

    .fc-daygrid-body {
        .fc-daygrid-day-events {
            min-height: 0px !important;
        }

        .fc-daygrid-day-frame {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}