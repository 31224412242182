.logo_toggle {
   background-color: #1e293b;
   width: 100%;
}

.profile_icon {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   background-color: #0162e8;
   text-align: center;
   padding-top: 3px;
   font-size: large;
}

.logo_small_letter {
   border-bottom: gray 1px solid;
   margin: -5px;
}

.search_input_div {
   position: relative;

   .search_input_form {
      outline: none;
      border: none;
      background-color: white;
      padding: 10px;
      border-radius: 11px;
      width: 100%;
   }

   .search_icon {
      position: absolute;
      margin-top: 11px;
      margin-left: -35px;
   }
}

.profile_picture {
   width: 30px;
   height: 30px;
   border-radius: 50%;
}

.dropdown-toggle::after {
   display: none !important;
}

.pull-left {
   cursor: pointer;
}

@media only screen and (max-width: 320px) {
   .big_dropdown {
      display: none;
   }
}

@media only screen and (min-width: 320px) {
   .small_dropdown {
      display: none
   }
}

.sidebar-toggle-closed-btn {
   @media screen and (max-width:992px) {
      margin-left: 3rem;
   }
}

.sidebar-toggle-open-btn {
   @media screen and (max-width:992px) {
      margin-left: 11.5rem;
   }
}