body {
    margin: 0;
    font-family: 'Jost', sans-serif !important;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  #backdrop {
    position:absolute;
    top:0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: rgb(0,0,0,0.2);
  }

  .text-center {
    width: 100%;
    position: relative;
    height: 100%;
}
.css-ahj2mt-MuiTypography-root,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
.MuiSelect-select,
.css-1w1rijm-MuiButtonBase-root-MuiButton-root,
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.css-sghohy-MuiButtonBase-root-MuiButton-root,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root,
.css-1e6y48t-MuiButtonBase-root-MuiButton-root,
.css-1kg8quh-MuiTypography-root,
.css-wjsjww-MuiChip-label,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1t3gu6w-MuiChip-root,
.css-11qr2p8-MuiButtonBase-root-MuiButton-root,
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {

  font-family: 'Jost', sans-serif !important;

}
.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));  
    right: calc(50% - (58px / 2));
    color: #0d6efd;
}
  // .view-date {
  //   padding: 5px;
  // }
  // .row-status{
  //   border: 2px solid  #F0F5F7; 
  //   padding: 10px;
  //   border-radius:3px;
  //   margin:5px
  // }

  .dropdown-toggle::after {
    margin-left: 50% !important;
 }

 .profile-icon {
  border-radius: 50%;
}

.table-responsive{
table{  
  thead{
    // font-size: 12px;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #eff4fa;
    th{
      padding: 1em;
      color:black;
      font-weight:600
    }
  }
 tbody{
    tr{
      background-color: #ffffff;
        // font-size: 12px;
        font-size: 0.9em;
        td{
          padding: 0.9em;
        }
      }
}
}
}
.sidebar-icons{
  color:#64748b
}
.date-picker-input{
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  outline: none;
  color: #333 !important;
  padding: 0.4rem;
  position: relative;
  width: 100%;
  margin: 0px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
  display: none;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none;
}
.title{
  font-size: 22px;
  color: #1e293b;
  margin-left: 0px;
  margin-top: 7px;
  font-weight: 600;
  margin-bottom: 20px;
}
.nav-tabs .nav-item .nav-link{
  color:#1e293b;
  font-weight: 600;
  padding: 8px 40px;
}
.nav-tabs .nav-link.active{
  color: #01579b;
}
.shadow-sm {
  background-color: #fff !important;
  border-radius: 5px;
}
.chart-card{
  // height: 26.1rem;
}
.btn-primary {
  background-color: #01579b;
  border: none;
}
.tab-styles {
  border: 1px solid #dcdcdc;
  box-shadow: 0px 1px 5px #e1e1e1;
  border-radius: 5px;
}
.table {
  margin-top: 0px !important;
}
.nav-item {
  // background: #eeeeee;
  // border: 1px solid #e9e9e9;
  border-radius: 5px 5px 0px 0px;
  margin-right: 6px;
}
.bg-wht{
  background-color: #fff;
  padding: 1% !important;
  border-radius: 15px;
  box-shadow: 0px 0px 5px #dadada;
}


// resume viewer 
.pg-viewer-wrapper{
  overflow: auto !important;
}