@mixin important-text{
		position: relative;
		margin: 0.5rem;
		line-height: 135%;
		cursor: pointer;
}

@mixin important-checkbox{
	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
	-moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
	transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
	content: "";
	position: absolute;
	z-index: 1;
}

@mixin important-check{
	content: "";
    position: absolute;
	width: 1rem;
	height: 1rem;
	background: #fff;
	border: 2px solid #f2f2f2;
}
@mixin important-input{
	border-color: blue;
	outline     : 0;
}
  @mixin border-radius($top-left:10px, $top-right:null, $bottom-right:null, $bottom-left:null){
	-webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
	   -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
		-ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
			border-radius: $top-left $top-right $bottom-right $bottom-left;  
  }

.form-control {
   box-shadow: none;
//    padding: 24px 20px ;
//    background: #f0f5f8;
   color: #868686;
   font-size: 15px;
//    border: 1px solid #F0F5F7;
   @include border-radius(8px);
   transition: all 0.3s ease-in-out;
   &.form-control-lg {
       padding: 0.55rem  0.75rem ;
   }
   &:focus{
      background: #fff;
      outline: none;
   }
}
.form-label{
   padding: 0;
   font-size: 15px;
   color: #202124;
   font-weight: 400;
   margin: 0 0 10px;
   
}
.input-group {
   .input-group-text:focus {
     @include important-input;
   }
}

.focused {
   .input-group {
       .input-group-text {
		@include important-input;
       }
   }

   .form-control {
       border-color    : blue;
       background-color: blue;
   }
}
 

//Add File Alternative Style

.file-field input[type="file"] {
   position      : absolute;
   cursor        : pointer;
   filter        : alpha(opacity=0);
   opacity       : 0;
   padding-bottom: 30px;
}

.file-field span {
   cursor: pointer;
}

.form-check {
	.form-check-label {
		@include important-text;
		color: #868686;
	}

	 
 
}




/// Custom check Box 

.form-check {
	.form-check-input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
		&:checked {
			+ {
				label {
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 4px;
						left: 9px;
						width: 6px;
						height: 14px;
						border: solid #0275bc;
						border-width: 0 2px 2px 0;
						transform: rotate(45deg);
					
					}
				}
			}
		}
	}
	label {
		position: relative;
		cursor: pointer;
		&:before {
			content: '';
			-webkit-appearance: none;
			background-color: transparent;
			border: 2px solid #0275bc;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
			padding: 10px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 10px;
			border-radius: 5px;
		}
	}
}


.form-check-radio {
	input[type="radio"] {
		display: none;
		&:checked {
			+ {
				label {
					&::after {
						position: absolute;
						content: "";
						top: 50%;
						left: 4px;
						width: 14px;
						height: 14px;
						margin-top: -4px;
						background: #0275bc;
						border-radius: 100%;
					
					}
				}
			}
		}
	}
	label {
		position: relative;
		display: inline-block;
		padding: 3px 3px 3px 30px;
			color: #868686;
		cursor: pointer;
		&::before {
			position: absolute;
			content: '';
			top: 50%;
			left: 0;
			width: 14px;
			height: 14px;
			margin-top: -8px;
			background: #fff;
			border: 1px solid #ccc;
			border-radius: 100%;
		}
	}
}
