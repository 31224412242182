.navbar-section {
   background: #1e293b;
   width: 100%;

   .navbar_icon {
      background-color: #18af11;
      width: 35px;
      height: 34px;
      border-radius: 5px;
      padding-top: 2px;

      div {
         height: 3px;
         width: 25px;
         background-color: #ffff;
         margin: 5px;
      }
   }

   .nav-item {
      .nav-link {
         font-size: 16px;
         color: #fff;
         border-bottom: double 4px transparent;
         padding-bottom: 1px;
         width: fit-content;
         margin-left: auto;

         &:hover {
            border-bottom-color: #18af11;
         }

         &.active {
            color: #18af11;
            border-bottom: double 4px #18af11;
         }
      }
   }

}