.timesheet-container {
    /* margin-top: 24px; */
    border-radius: 8px;
    // height: 100vh !important;
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;

        @media screen and (max-width:992px) {
            background-color: #f5f5f5;

        }
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        --webkit-box-shadow: inset 0 0 6px rgba(117, 117, 117, 0.1);
        background-color: transparent;

        @media screen and (max-width:992px) {
            background-color: #abb0b4;

        }
    }

    &:hover {
        &::-webkit-scrollbar {
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            // border-radius: 10px;
            // --webkit-box-shadow: inset 0 0 6px rgba(117, 117, 117, 0.1);
            background-color: #abb0b4;
        }
    }

}

.day-work-status {
    cursor: pointer !important;
    overflow-y: auto !important;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        --webkit-box-shadow: inset 0 0 6px rgba(117, 117, 117, 0.1);
        background-color: transparent;
    }

    &:hover {
        &::-webkit-scrollbar {
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #abb0b4;
        }
    }
}

.date-map-cls {
    text-align: center;
    height: 65px;
    border-radius: 8px 8px 0px 0px;
    background-color: #E1E6EF;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    min-width: 1700px;
    gap: 10px;

}

.show-dates {
    width: 220px;
}

.reports-container {
    padding: 24px;
    border-radius: 0px 0px 8px 8px !important;
    height: max-content;
    /* overflow-x: scroll; */
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    // width: 1660px;

}

.timesheet-card {
    border-radius: 8px !important;
}

.day-projects-map {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.report-card {
    border: 1px solid #E3E3E3 !important;
    height: 110px;
    width: 220px;
    border-radius: 8px;
    padding: 10px;
    /* background: #FFF !important; */
}

.empty-card {
    background-color: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;

}

.time-hours {
    color: #9AA6BB;
    font-family: Inter;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
}

.text-field-style {
    border: none !important;
}

.activity-Box {
    width: 94px;
    height: 22px;
    padding: 8px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: rgba(236, 154, 0, 0.10);
    margin-bottom: 5px;

}

.activity-typo {
    color: #EC9A00;
    font-family: "DM Sans" !important;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
}

.add-timesheet {
    color: #B0B0B0;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.addnew-cls {
    display: flex;
    padding: 15px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    height: 80px;
}

.submit-btn {
    align-self: flex-end;
}
.sbtn:disabled{
    background-color:#0c1c2c !important
    }

.sbtn {
    width: 100px;
    // height: 44px;
    padding: 10px;
    color: #F6F6F6 !important;
    border-radius: 8px;
    color: #FFF;
    font-family: Inter;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    background-color: #01579B !important;
    text-transform: capitalize !important;
    font-family: "Jost", sans-serif !important;
}

.add-new-typo {
    color: #2C3E50;
    font-family: "DM Sans" !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    text-transform: capitalize;
    cursor: pointer;
}

.ts-sts-btn{
    background-color: #FFBB04 !important;
    border-radius: 12px !important;
    color: black !important;
    height: 23px !important;
    font-size: 12px !important;
    font-size: 600;
    text-transform:none !important;
}
.ts-approved-btn{
    background-color: green !important;
    border-radius: 12px !important;
    color: white !important;
    height: 23px !important;
    font-size: 12px !important;
    font-size: 600;
    text-transform:none !important;
}