.repor-card-map{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap:10px;
    padding: 10px;
}
.report-cards-count{
    display: flex;
    flex-direction: row;
}

.day-wise-reportCard{
    display: flex;
    flex-direction: column;
}

.approve-section-cls{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 10px 10px 0px !important;
    width: 100%;
    height: 55px;
    gap:10px
}

.rejbtn{
    width: 100px;
    height: 35px !important;
    padding: 0px !important ;
    color: #F6F6F6 !important;
    border-radius: 4px !important;
    color: #FFF;
    font-family: Inter;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background-color: #cd6258 !important;
    text-transform: capitalize !important;
}