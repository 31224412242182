.up {
    border-color: transparent #1e293b transparent transparent;
    border-style: inset;
    border-width: 500px 800px 0 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &::after {
        content: "Naresh";
        display: block;
    }
}

.banner-section {
    background-image: url("../../assets/images/polygon-white-bg.png");
    height: 500px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom: solid #1e293b 1px;

    h1 {
        font-size: 35px;
        color: #1e293b;
        line-height: 60px;
        margin-top: 150px;
        font-weight: bold;

        span {
            color: #18af11;
        }
    }
}

.hexagon-icon {
    li {
        position: relative;
        list-style-type: none;
        width: 200px;
        padding-bottom: 235px;
        float: left;
        overflow: hidden;
        visibility: hidden;
        -webkit-transform: rotate(-60deg) skewY(30deg);
        -ms-transform: rotate(-60deg) skewY(30deg);
        transform: rotate(-60deg) skewY(30deg);
        cursor: pointer;
        margin-right: 10px;

        * {
            position: absolute;
            visibility: visible;
        }

        .hexagon-content {
            width: 100%;
            height: 100%;
            text-align: center;
            color: #fff;
            overflow: hidden;
            -webkit-transform: skewY(-30deg) rotate(60deg);
            -ms-transform: skewY(-30deg) rotate(60deg);
            transform: skewY(-30deg) rotate(60deg);
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            h1 {
                bottom: -100%;
                font-style: italic;
                font-size: 1.5em;
                padding-top: 100%;
                padding-bottom: 100%;
                color: #fff;
                padding: 0 5%;
                line-height: normal;
                background-color: #18af11;
                background-color: rgba(24, 175, 17, 0.8);
                -webkit-transition: top 0.2s ease-out, bottom 0.2s ease-out, 0.2s padding 0.2s ease-out;
                -ms-transition: top 0.2s ease-out, bottom 0.2s ease-out, 0.2s padding 0.2s ease-out;
                transition: top 0.2s ease-out, bottom 0.2s ease-out, 0.2s padding 0.2s ease-out;
            }

            img {
                left: -100%;
                right: -100%;
                width: auto;
                height: 100%;
                margin: 0 auto;
            }

            &:hover {
                h1 {
                    bottom: -3%;
                    padding-top: 43%;

                    height: 100%;
                }
            }
        }
    }

    .li-one {
        margin-left: 40px;
        margin-top: 5px;
    }

    .li-two {
        margin-left: 44px;
        margin-top: 93px;
    }

    .li-three {
        margin-left: 85px;
        margin-top: -90px;
    }
}

.new-banner-section {
    background-color: #f2f2f2;

    .banner-heading {
        font-feature-settings: normal;
        font-variant: normal;
        font-size: 2.5em;
        position: absolute;
        top: 50%;
        width: 100%;
        line-height: 4rem;
        transform: translateY(-50%);

        h2 {
            font-weight: 600;
            font-size: 55px;
            line-height: 70px;
            position: relative;
            top: 0%;

            @media screen and (max-width:1200px) {
                font-size: 45px;
                line-height: 50px;
            }
        }

        p {
            font-size: 18px;
            margin-bottom: 0px;

            @media screen and (max-width:1200px) {
                font-size: 1.5rem !important;
            }

            @media screen and (max-width:992px) {
                top: 0 !important;
                font-size: 1.5rem !important;

            }
        }

        span {
            color: #00be2f;
        }

        ul {
            margin: auto;
            float: left;
            padding: 0px;

            li {
                float: left;
                list-style-type: none;
                margin-right: 25px;

                .ban-btn {
                    background-color: #fff !important;
                    color: #1E293B !important;
                    border-radius: 25px !important;
                    padding: 10px 30px !important;
                }

                .ban-btn2 {
                    background-color: #00BE2F !important;
                    color: #fff !important;
                    border-radius: 25px !important;
                    padding: 10px 25px !important;
                }
            }
        }
    }

    .img-width {
        background-size: cover;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .new-banner-section .banner-heading p {
        font-size: 2rem;
        position: relative;
        top: 15%;
        line-height: 2.5rem;
    }

    .new-banner-section .img-width {
        background-size: cover;
        max-height: 300px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .new-banner-section .banner-heading p {
        font-size: 2rem;
        top: 15%;
        line-height: 2.5rem;
    }

    .new-banner-section .img-width {
        background-size: cover !important;
        max-height: none !important;
        max-height: 300px;
        width: auto;
    }

}