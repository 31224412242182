// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
#login {
  background-color: #ededed;
  display: flex;
  align-items: center;
  // padding-top: 152px;
  // padding-bottom: 152px;
  form {
    border: 3px solid #f1f1f1;
  }

  input[type=text],
  input[type=password] {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 43px;
    background-color: #e6e6e6;
    height: 50px;
    outline: none;
  }

  .button_login {
    background-color: #18af11;
    color: white;
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 30px;
  }

  .forgot-credentials {
    color: #666666;
    :hover{
      text-decoration: underline;
    }
  }

  button:hover {
    opacity: 0.8;
  }

  .cancelbtn {
    width: auto;
    padding: 10px 18px;
    background-color: #f44336;
  }

  .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
  }

  .img_mic{
    width: 25px;
    height: 25px;
  }

  img.avatar {
    width: 40%;
    border-radius: 50%;
  }

  .profile-image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    // border-radius: 50%;
    // border: 20px solid #ffffff;
  }

  .profile-background {
    background-color: white;
    border-radius: 50%;
    margin: 66px;
    height: 250px;
    width: 250px;
    padding: 42px;

  }

  // .container {
  //   padding: 16px;
  // }

  .profile {
    background-color: #c8dff1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    
  }


  .login-details {
    background-color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  span.psw {
    float: right;
    padding-top: 16px;
  }

  .microsoft_button{
    font-family: sans-serif;
    font-weight: 600;
    border: 1px gray solid;
  }
  .or_text{
    text-align: center;
  }
  /* Change styles for span and cancel button on extra small screens */
  @media screen and (max-width: 300px) {
    span.psw {
      display: block;
      float: none;
    }

    .cancelbtn {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .login-details {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .profile-image{
    display: none;
  }
  #login{
    padding-top: 20px ;
    padding-bottom: 20px;
  }
  
}
