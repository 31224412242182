.stepper_line_1 {
    border-left: 1px solid gray;
    padding-left: 20px;
    position: relative;

    .fw-bold {
        position: absolute;
        margin-top: -20px;
    }
}

.stepper_circle_1 {
    height: 27px;
    width: 27px;
    background-color: #14A44D;
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: -20px;
    padding-left: 9px;
    padding-top: 2px;
}

.stepper_circle_2 {
    height: 27px;
    width: 27px;
    background-color: #ffc107;
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: -20px;
    padding-left: 9px;
    padding-top: 2px;
}

.stepper_circle_3 {
    height: 27px;
    width: 27px;
    background-color: #6c757d;
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: -20px;
    padding-left: 9px;
    padding-top: 2px;
}

.stepper_circle_4 {
    height: 27px;
    width: 27px;
    background-color: #ff4444;
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: -20px;
    padding-left: 9px;
    padding-top: 2px;
}

.overflow_card {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.overflow_card_skills {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.candicate-card {
    &-success {
        border-top: 3px solid #18af11 !important;
    }

    &-error {
        border-top: 3px solid #dc3545 !important;
    }

    &-warning {
        border-top: 3px solid #ffc107 !important;
    }

    &-info {
        border-top: 3px solid #0d6efd !important;
    }

}

.resume_view_modal {
    background-color: #f4f4f4;
    text-align: center;
    height: 80vh;
}

.content-wrap {
    position: relative;

    .css-1e0d89p-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
        color: gray;
    }

    .statusLine {
        left: 1.2rem;
        z-index: 9;
    }
}

.content-wrap::after {
    position: absolute;
    top: calc(50% - 1px);
    right: .4rem;
    left: 3.4rem;
    Content: "";
    background-color: grey;
    height: 2px;
}