h2 {
    font-size: 32px;
    color: #1e293b;
}

.info_section {
    background-color: #f8f9fc;
    color: #000;

    .info_grid {
        text-align: center;
        color: #7c7c8d;

        h2 {
            color: #1e293b;
            font-size: 35px;
            font-weight: bold;

            span {
                color: #f7cc53;
            }
        }
    }
}

.job_grid {
    padding: 3% 0 3.5% 0px;
    color: #3b3c3f;
    line-height: 30px;
    font-weight: 400;

    h2 {
        color: #1e293b;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;

        span {
            color: #00BE2F;
        }
    }

    p {
        line-height: 22px;
    }

    ul {
        margin: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 0px;

        li {
            margin: 10px 30px 10px 0px;
            line-height: 22px;
            background: #fff;
            padding: 10px;
            box-shadow: 1px 1px 3px #cccccc;
            border-radius: 5px;
            list-style: none;
        }
    }
    .dis-btn {
        border-radius: 25px !important;
        background-color: #00BE2F !important;
    }
}

.job_grid2 {
    padding: 3% 0 3.5% 0px;
    color: #3b3c3f;
    line-height: 30px;
    font-weight: 400;

    h2 {
        color: #1e293b;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;

        span {
            color: #00BE2F;
        }
    }
    h4 {
        color: #1e293b;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    p {
        line-height: 22px;
    }

    ul {
        margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0px;

        li {
            margin: 20px 0px;
            line-height: 22px;
            background: #FEBDB5;
            padding: 15px;
            box-shadow: 1px 1px 3px #cccccc;
            border-radius: 5px;
            list-style: none;
        }
    }
    .dis-btn {
        border-radius: 25px !important;
        background-color: #00BE2F !important;
        float: right;
    }
}

.job_grid3 {
    padding: 3% 0 3.5% 0px;
    color: #3b3c3f;
    line-height: 30px;
    font-weight: 400;

    h2 {
        color: #1e293b;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;

        span {
            color: #00BE2F;
        }
    }
    h4 {
        color: #1e293b;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    p {
        line-height: 22px;
    }

    ul {
        margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0px;

        li {
            margin: 20px 0px;
            line-height: 22px;
            background: #ffffff;
            padding: 15px;
            box-shadow: 1px 2px 7px #cacaca;
            border-radius: 5px;
            list-style: none;
            width: 31%;
            float: left;
            margin: 11px;
            min-height: 250px;
        }
    }
    .dis-btn {
        border-radius: 25px !important;
        background-color: #00BE2F !important;
        float: left;
    }
    .dis-btn2 {
        border-radius: 25px !important;
        background-color: #00BE2F !important;
        float: right;
    }
}

.img-fluid2 {
    max-width: 80%;
    height: auto;
  
}

.top_jobs {
    padding: 5% 0 5% 0px;
    color: #3b3c3f;
    line-height: 30px;
    font-weight: 400;
    background: #F1F1F1;

    p {
        color: #1E293B;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        a {
            color: #00BE2F;
            font-size: 14px;
            text-decoration: none;
        }
    }

    h2 {
        color: #1e293b;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 0px;
        span {
            color: #00BE2F !important;
        }
    }
    h4 {
        font-size: 17px;
        margin-bottom: 5px;
        font-weight: 600;
    }
    ul {
        margin: 0;
        list-style: none;
        padding-left: 0;

        li {
            padding: 15px;
            border: solid 1px #ecedf2;
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            color: #000;
            font-size: 15px;
            background-color: #fff;
            box-shadow: 1px 1px 3px #dddddd;
           

            .icon {
                width: 100px;
                height: 100px;
                margin-right: 20px;
                border-radius: 50%;
                color: #fff;
                font-weight: 600;
            }
        }
    }
}

.btn-cost {
    background-color: #13870e !important;
    padding: 10px 20px !important;
    border-radius: 0px !important;

    &:hover {
        background-color: #01579b !important;
        box-shadow: 0px 0px 10px #7c7c7c;
    }
}

.section-two {
    position: relative;
    padding: 0;
    color: #333;
    padding: 4.875em 0 3.25em 0;
    background: #ebf2fa;

    h2 {
        line-height: 35px;
        font-weight: 600;
        padding-bottom: 10px;
    }

    .section-two-content {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 2;
        padding: 0;
    }

    >.container {
        padding-top: 3em;
        padding-bottom: 4.125em;
        float: none;
        overflow: hidden;
    }

    >div[class*='col-'] {
        &:not(.section-two-content) {
            padding-top: 6.5em;
            padding-bottom: 8.125em;
            float: none;
            overflow: hidden;
        }
    }
}

.background-image-holder {
    &:not([class*='col-']) {
        width: 100%;
    }

    will-change: transform,
    top;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 0;
    transition: 0.3s linear;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    opacity: 0;
    background: #232323;

    img {
        display: none;
    }
}