.sideNavBar_small {
    background-color: #1e293b;
    height: 100%;

    .image-small {
        height: 40px;
        width: 40px;
        object-fit: cover;
        margin-top: 12px;
        border: 5px solid white;
        border-radius: 50%;
    }
}

.text-left {
    transition: 5s ease;
}

.sideNavBar_small:focus {
    width: auto;
}

.profile_icon_small {
    width: 50px;
    height: 50px;
}

.pull-left-text {
    text-align: left;
    white-space: nowrap;
    padding-right: 30px;
}

.sideNavbar_big {
    transition: all 0.7s ease;
}

.profile_icon {
    background-color: #18af11;
    width: 40px;
    height: 40px;
    font-size: 15px;
    cursor: pointer;
}

.timesheet-icon {
    height: 24px;
    width: 24px
}

.sidebar {
    @media screen and (max-width:992px) {
        position: fixed;
        // top: 60px;
        left: 0;
        z-index: 999;
        height: 100%;

    }
}