.title {
    font-size: 30px;
    color: #0275bc;
    margin-left: 3px
}

#content {
    width: 100%;
}

.work-report-section {

    .round-add-btn {
        width: 30px;
        height: 30px;
        background: #18af11;
        border-radius: 50%;
        border: 0;
        color: #fff;
        padding: 0;
        margin: 0;
        font-weight: bold;
    }

    .round-remove-btn {
        width: 30px;
        height: 30px;
        background: #ff0000;
        border-radius: 50%;
        border: 0;
        color: #fff;
        padding: 0;
        margin: 0;
        font-weight: bold;
    }
}

.mobile-sidebar-overlay-container {
    @media screen and (max-width:992px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 997;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

    }
}