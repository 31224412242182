@mixin scrollableStyles {
    &::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
    }


    &::-webkit-scrollbar-track {
        --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #f5f5f5;
        -webkit-border-radius: 5px;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        // background-color: #dfe2e6 !important;
        // width: 6px !important;
        --webkit-box-shadow: inset 0 0 6px rgba(117, 117, 117, 0.1);
        background-color: #d0cece;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #757d85;
    }
}


//        Table Component Styles   //

.table-container-cls {
    // padding-right: 5px !important;

    table,
    td,
    tr,
    th {
        // border: 0px !important;
    }

    table {
        // border-spacing: 0px 10px !important;
    }

    th {
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 12.86px !important;
        color: #000000 !important;
        min-height: 58px !important;
        // max-height: 158px !important;
        background-color: #e0e9f9;

    }

    tr {
        // background-color: #EAF0FD !important;
        // border-radius: 12px !important;
        min-height: 58px !important;

        td {
            font-size: 14px !important;
            font-weight: 500 !important;
            line-height: 17.64px !important;
            color: #1B2559 !important;

        }

        .actions-btn-cls {
            background-color: rgba(0, 0, 0, 0.04) !important;

            &:hover {
                background-color: #e0e9f9 !important;
            }
        }
    }

    .sticky_right_header_column {
        position: sticky;
        right: 0;
        background-color: #fff;
        min-width: 50px !important;
        box-shadow: inset 1px 0px 0px #e0e0e0;
        z-index: 6;
    }

    .sticky_right_column {
        position: sticky;
        right: 0;
        background-color: #ffffff;
        min-width: 50px !important;
        box-shadow: inset 1px 0px 0px #e0e0e0;
        z-index: 5;
    }


    // .MuiTableRow-root {
    //     td:first-child {
    //         border-top-left-radius: 10px !important;
    //         border-bottom-left-radius: 10px !important;
    //     }

    //     td:last-child {
    //         border-top-right-radius: 10px !important;
    //         border-bottom-right-radius: 10px !important;
    //     }
    // }

    @include scrollableStyles
}


.table-pagination-container {
    [class*="TablePagination-toolbar"] {
        display: flex !important;
        align-items: center !important;
    }

    p {
        margin-bottom: 0px !important;
    }
}




//////////////////////////////////////////
//           Assets Modal Styles             ///
/* General Container Styles */
.asset-list-container {
    padding: 10px;
    max-width: 100%;
    // margin: auto;
    // background-color: #f9f9f9;
    // border: 1px solid #ddd;
    // border-radius: 8px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* List Styles */
.asset-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.asset-item {
    padding: 15px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 10px;
}

.asset-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Checkbox Styles */
.asset-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
}

.asset-checkbox {
    display: none;
    /* Hides the default checkbox */
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #007bff;
    border-radius: 4px;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.asset-checkbox:checked+.custom-checkbox {
    background-color: #007bff;
    // border-color: #0056b3;
}

.custom-checkbox::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 5px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg) scale(0);
    top: 50%;
    left: 50%;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
}

.asset-checkbox:checked+.custom-checkbox::after {
    transform: rotate(-45deg) scale(1);
    top: 25%;
    left: 20%;
}

/* Asset Details Styles */
.asset-details {
    margin-top: 10px;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 4px;
    animation: fadeIn 0.5s ease-in-out;
}

.asset-details p {
    margin: 0 0 1px;
}

.serial-input {
    display: block;
    width: 100%;
    margin-top: 5px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.existing-assets-list-cls {
    max-height: 150px !important;
    overflow-y: auto;
    padding-left: 1.2rem;
    padding-bottom: 8px;

    li {
        font-size: 0.9rem;
        margin-bottom: 5px;

        p {
            font-size: 0.85rem;

            span {
                font-size: 0.9rem;

            }
        }
    }

    @include scrollableStyles;
}

.add-asset-btn-cls {
    min-width: 10px !important;
    min-height: 10px !important;
    padding: 0px !important;

    &:hover {
        background-color: transparent !important;
    }
}

.assets-modal-body-cls {
    max-height: 500px !important;
    overflow-y: auto !important;
}