.profile_picture_profile {
    position: relative;

    .profile_img_profile {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: center;
        object-fit: cover;
        background-image: url(../../assets/images/profile.webp);
        background-repeat: no-repeat;
        filter: drop-shadow(0 0 0.35vmax rgb(4, 4, 4));
    }

    .edit_profile_pic {
        border: 3px solid white;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .profile_img_letter {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
}

.emp_name {
    max-width: 170px;
    text-align: center;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    overflow: hidden;
}
.emp_email{
    max-width: 170px;
    overflow: hidden;
}

.designation_text {
    color: #01579b;
}

.personal_skills {
    h5 {
        font-weight: bold !important;
        color: #01579b;
    }

}

.align {
    line-height: 1.5rem !important;
}

.personal_info {
    max-width: 220px;

    .icon {
        width: 20px;
        height: 20px;
        color: #01579b;

        span {
            display: inline-block;
            width: 5rem;
        }

        .row {
            margin: 0 !important;
        }
    }

    .btntxt {
        margin-left: 1rem;
    }
}

.professional_details {
    .designation_toolTip {
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .code_toolTip {
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .address_toolTip {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .name {
        color: #01579b;
    }

    .icon {
        color: #01579b;
    }

    .icongreen {
        color: #18af11;
    }
}

.sub_head_profile {
    white-space: nowrap;
}

.text_profile {
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.skills {
    table {
        width: 96% !important;

        .icon {
            color: #01579b;
            width: 20px;
            height: 20px;
        }

        th {
            color: #01579b;
        }
    }
}